import axios from "../../../configs/axios";

export const loginWithJWT = (user, loginType) => {
	return (dispatch) => {
		let formData = new FormData();
		formData.append("name", user.username);
		formData.append("pass", user.password);
		formData.append("qase-cms", true);

		let url =
			loginType === "CA"
				? "/login"
				: `${process.env.REACT_APP_US_SITE_URL}/api/v1/login`;

		axios
			.post(url, formData)
			.then((response) => {
				console.log(response.data);
				if (!response.data.success) {
					return dispatch({
						type: "LOGIN_FAILED",
						payload: {
							loginType,
							message: response.data.message || "Login Failed",
						},
					});
				}

				let loggedInUser;
				loggedInUser = response.data.data;
				loggedInUser.role = "admin";
				console.log(loggedInUser);

				dispatch({
					type: "LOGIN_WITH_JWT",
					payload: {
						loginType,
						loggedInUser,
					},
				});
			})
			.catch((err) => console.log(err));
	};
};

export const logoutWithJWT = () => {
	return (dispatch) => {
		localStorage.removeItem("loggedUser");
		dispatch({ type: "LOGOUT_WITH_JWT", payload: {} });
		//history.push("/login")
	};
};

export const changeRole = (role) => {
	return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};

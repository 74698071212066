import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
	{
		id: "analyticsDash",
		title: "Analytics",
		type: "item",
		icon: <Icon.Circle size={12} />,
		navLink: "/",
	},
	{
		type: "groupHeader",
		groupTitle: "APPS",
	},
	{
		id: "cases",
		title: "Cases",
		type: "collapse",
		icon: <Icon.Home size={20} />,
		children: [
			{
				id: "new-cases",
				title: "New Cases",
				type: "item",
				icon: <Icon.Circle size={12} />,
				navLink: "/cases/new-cases",
			},
			{
				id: "contingency-cases",
				title: "Contingency Cases",
				type: "item",
				icon: <Icon.Circle size={12} />,
				navLink: "/cases/contingency-cases",
			},
			{
				id: "case-events",
				title: "Events",
				type: "item",
				icon: <Icon.Circle size={12} />,
				navLink: "/cases/events",
			},
			{
				id: "case-rejections",
				title: "Case Rejections",
				type: "item",
				icon: <Icon.Circle size={12} />,
				navLink: "/cases/case-rejections",
			},
			{
				id: "case-attach-clientid",
				title: "Attach Client ID",
				type: "item",
				icon: <Icon.Circle size={12} />,
				navLink: "/cases/attach-client-id",
			},
			{
				id: "vfm-cases",
				title: "VFM Cases",
				type: "item",
				icon: <Icon.Circle size={12} />,
				navLink: "/cases/vfm-cases",
			},
		],
	},
	{
		id: "appointmens",
		title: "Appointments",
		type: "collapse",
		icon: <Icon.Calendar size={20} />,
		children: [
			{
				id: "ic-appointment-reports",
				title: "IC Reports",
				type: "item",
				icon: <Icon.Circle size={12} />,
				navLink: "/appointments/ic-reports",
			},
			{
				id: "scheduled-appointment-reports",
				title: "Scheduled Appt",
				type: "item",
				icon: <Icon.Circle size={12} />,
				navLink: "/appointments/scheduled-reports",
			},
			{
				id: "detailed-appointment-reports",
				title: "Detailed Appt",
				type: "item",
				icon: <Icon.Circle size={12} />,
				navLink: "/appointments/detailed-reports",
			},
			// {
			//   id: "appointment-reports",
			//   title: "Reports",
			//   type: "item",
			//   icon: <Icon.Circle size={12} />,
			//   navLink: "/appointments/reports"
			// },
			// {
			//   id: "appointment-events",
			//   title: "Events",
			//   type: "item",
			//   icon: <Icon.Circle size={12} />,
			//   navLink: "/appointments/events"
			// },
		],
	},
	{
		id: "practice_areas",
		title: "Practice Area",
		type: "item",
		icon: <Icon.Activity size={20} />,
		navLink: "/practice_area",
	},
	{
		id: "transactions",
		title: "Transactions",
		type: "item",
		icon: <Icon.CreditCard size={20} />,
		navLink: "/transactions",
	},
	// {
	//   id: "users",
	//   title: "Users",
	//   type: "item",
	//   icon: <Icon.User size={20} />,
	//   navLink: "/users",
	// },
];

export default navigationConfig;

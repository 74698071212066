const userData = JSON.parse(localStorage.getItem("loggedUser"));
const initState = userData || {
  caUser: {
    loggedIn: false,
    userRole: false,
    loggedInUser: false,
    errorMessage: false,
  },
  usUser: {
    loggedIn: false,
    userRole: false,
    loggedInUser: false,
    errorMessage: false,
  },
  // loggedIn: function () {
  //   return this.caUser.loggedIn && this.usUser.loggedIn
  // }
}

export const login = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_WITH_JWT": {
      let key = action.payload.loginType === "CA" ? "caUser" : "usUser"
      let newState = {
        ...state,
        [key]: {
          ...state[key],
          loggedIn: true,
          userRole: action.payload.loggedInUser.role,
          loggedInUser: action.payload.loggedInUser,
        }
      }
      localStorage.setItem("loggedUser", JSON.stringify(newState));
      return newState;
    }
    case "LOGOUT_WITH_JWT": {
      return {
        caUser: {
          loggedIn: false,
          userRole: false,
          loggedInUser: false,
          errorMessage: false,
        },
        usUser: {
          loggedIn: false,
          userRole: false,
          loggedInUser: false,
          errorMessage: false,
        },
      }
    }
    case "LOGIN_FAILED": {
      let key = action.payload.loginType === "CA" ? "caUser" : "usUser"
      return {
        ...state,
        [key]: {
          ...state[key],
          errorMessage: action.payload.message,
        }

      }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    default: {
      return state
    }
  }
}

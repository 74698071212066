import axios from "axios";
// import { history } from "../history";
import { store } from "../redux/storeConfig/store";
import { logoutWithJWT } from "../redux/actions/auth/loginActions";
import { configure } from "../utility/hook/useAxios";
axios.defaults.baseURL = process.env.REACT_APP_SITE_URL + "/api/v1";
axios.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data

		// console.log(response);
		if (response.data.context === "Check Auth") {
			store.dispatch(logoutWithJWT());
		}
		return response;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		return Promise.reject(error);
	}
);

configure({ axios, cache: false });

export default axios;
